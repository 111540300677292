
import _ from "lodash"
import ep from "~/api/endpoint"
import {
  TAG_CHAT_COLORS,
  TAG_CHAT_DEFAULT_COLOR,
  TAG_COMPONENT_BY_TYPES,
  TAG_TYPE_BY_GROUPS,
  TAG_GROUP_BY_TYPES,
  TAG_TYPES,
} from "../../constants/tag"

export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "tag_default", // "tag_contact" | "tag_chat" | "tag_case" | "tag_broadcast" | "tag_default"
    },
    popover_btn_type: {
      type: String,
      default: "button", // "button" | "icon"
    },
    popover_btn_text: {
      type: String,
      default: "เพิ่มแท็ก",
    },
    contact_id: {
      type: String,
      default: "",
    },
    data_id: {
      type: String,
      default: "",
    },
    is_limit_exceeded: {
      type: Boolean,
      default: false,
    },
    show_tag_list: {
      type: Boolean,
      default: false,
    },
    show_tag_button: {
      type: Boolean,
      default: false,
    },
    show_untag_button: {
      type: Boolean,
      default: false,
    },
    show_create_button: {
      type: Boolean,
      default: false,
    },
    show_edit_button: {
      type: Boolean,
      default: false,
    },
    show_remove_button: {
      type: Boolean,
      default: false,
    },
    onTag: {
      type: Function,
      default: () => {},
    },
    onUntag: {
      type: Function,
      default: () => {},
    },
    onCreateTag: {
      type: Function,
      default: () => {},
    },
    onEditTag: {
      type: Function,
      default: () => {},
    },
    onRemoveTag: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show_popover: false,
      loading: false,
      loadmore_loading: false,
      tag_data: {
        data: [],
        total: 0,
        limit: 40,
        skip: 0,
      },
      new_tag_data: {
        keyword: "",
        group: "",
        type: "",
        color_hex_code: TAG_CHAT_DEFAULT_COLOR,
      },
      current_editing_tag: {
        _id: "",
        keyword: "",
        group: "",
        type: "",
        color_hex_code: "",
      },
      search_input: "",
      edit_input: "",
      is_editing_tag: false,
      show_remove_dialog: false,
      tag_chat_colors: Object.values(TAG_CHAT_COLORS),
      tag_group_by_types: TAG_GROUP_BY_TYPES,
      tag_component_by_types: TAG_COMPONENT_BY_TYPES,
      tag_type_by_groups: TAG_TYPE_BY_GROUPS,
      tag_types: TAG_TYPES,
    }
  },
  computed: {
    is_disabled() {
      return this.is_limit_exceeded && this.show_tag_list
    },
    tag_group() {
      return (
        this.tag_group_by_types[this.type] ??
        this.tag_group_by_types.tag_default
      )
    },
    component_type() {
      return (
        this.tag_component_by_types[this.type] ??
        this.tag_component_by_types.tag_deafult
      )
    },
    tag_list() {
      return _.chain(this.tag_data)
        .get("data", [])
        .map((item) => {
          const regex = new RegExp(this.escapeRegExp(this.search_input), "i")
          const highlight_word = this.$smartChatHelper.highlightSearchMatch(
            this.search_input,
            item.keyword,
            false,
            regex
          )
          return {
            ...item,
            highlight_word: highlight_word,
            checked: this.tags.find((tag) => tag._id == item._id),
          }
        })
        .value()
    },
    selected_color() {
      if (this.is_suggest_new_tag) {
        return this.new_tag_data.color_hex_code
      } else if (this.is_editing_tag) {
        return this.current_editing_tag.color_hex_code
      } else {
        return ""
      }
    },
    is_suggest_new_tag() {
      this.resetEditTagForm()

      if (!this.search_input) return false

      const found = this.tag_data.data.find(
        (item) => item.keyword === this.search_input
      )
      if (found) {
        return false
      } else {
        this.new_tag_data.keyword = this.search_input
        return true
      }
    },
    current_editing_keyword_length() {
      return _.get(this.current_editing_tag, "keyword.length", 0)
    },
    no_more() {
      return this.tag_data.data.length >= this.tag_data.total
    },
    disabled_load_more() {
      return this.loadmore_loading || this.loading || this.no_more
    },
  },
  methods: {
    async onClickPopover() {
      this.loading = true
      this.resetSkip()
      const res = await this.getTagData()
      const data = _.get(res, "data", [])
      const total = _.get(res, "total", 0)
      this.$set(this.tag_data, "data", data)
      this.$set(this.tag_data, "total", total)
      this.loading = false
    },
    async getTagData() {
      try {
        const res = await this.$ohoMemberApi.$get(ep.keyword, {
          params: {
            group: this.tag_group,
            "$sort[count]": "-1",
            "keyword[$search]": this.search_input || undefined,
            $limit: this.tag_data.limit,
            $skip: this.tag_data.skip,
          },
        })
        return res
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    loadMoreTag: _.debounce(async function () {
      this.loadmore_loading = true
      this.tag_data.skip += this.tag_data.limit
      const res = await this.getTagData()
      const data = _.get(res, "data", [])
      this.tag_data.data.push(...data)
      this.loadmore_loading = false
    }, 300),
    searchTag: _.debounce(async function () {
      this.loading = true
      this.resetEditTagForm()
      this.resetSkip()
      const res = await this.getTagData()
      const data = _.get(res, "data", [])
      const total = _.get(res, "total", 0)
      this.tag_data.data = data
      this.tag_data.total = total
      this.loading = false
    }, 300),
    async submitCreateTag(data) {
      try {
        const body = this.generateNewTagAPIBody(data)
        const res = await this.$ohoMemberApi.$post(ep.keyword, body)
        this.tag_data.data.push(res)
        this.onCreateTag(res)
        this.resetNewTagForm()
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    async submitEditTag(data) {
      try {
        const body = this.generateEditTagAPIBody(data)
        const res = await this.$ohoMemberApi.$post(ep.keyword, body)

        const selected_tag_index = this.tags.findIndex(
          (item) => item._id === data._id
        )
        const all_tag_list_index = this.tag_data.data.findIndex(
          (item) => item._id === data._id
        )

        if (selected_tag_index > -1) {
          this.$set(this.tags, selected_tag_index, res)
        }
        if (all_tag_list_index > -1) {
          this.$set(this.tag_data.data, all_tag_list_index, res)
        }

        this.resetEditTagForm()
      } catch (error) {
        if (_.get(error, "response.status") === 400) {
          this.$message.error("แก้ไขไม่สำเร็จ เนื่องจากแท็กซ้ำ")
        }
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    async submitRemoveTag(data) {
      try {
        await this.$ohoMemberApi.$delete(`${ep.keyword}/${data._id}`)

        const selected_tag_index = this.tags.findIndex(
          (item) => item._id === data._id
        )
        const all_tag_list_index = this.tag_data.data.findIndex(
          (item) => item._id === data._id
        )

        if (selected_tag_index > -1) {
          this.tags.splice(selected_tag_index, 1)
        }
        if (all_tag_list_index > -1) {
          this.tag_data.data.splice(all_tag_list_index, 1)
        }
      } catch (error) {
        this.$logger.error(_.get(error, "response.data", "เกิดข้อผิดพลาด"))
      }
    },
    editTag(data) {
      this.resetNewTagForm()
      this.$nextTick(() => {
        this.current_editing_tag = {
          _id: data._id,
          keyword: data.keyword,
          group: data.group,
          type: this.getTagTypeByGroup(data.group),
          color_hex_code: data.color_hex_code,
        }
        this.is_editing_tag = true
      })
    },
    onSelectColor(color) {
      if (this.is_suggest_new_tag) {
        this.new_tag_data.color_hex_code = color
      } else if (this.is_editing_tag) {
        this.current_editing_tag.color_hex_code = color
      }
    },
    openRemoveDialog() {
      this.show_remove_dialog = true
    },
    closeRemoveDialog() {
      this.show_remove_dialog = false
      this.resetEditTagForm()
    },
    async confirmRemoveTag() {
      await this.submitRemoveTag(this.current_editing_tag)
      this.closeRemoveDialog()
    },
    cancelRemoveTag() {
      this.closeRemoveDialog()
    },
    getTagTypeByGroup(group) {
      return (
        this.tag_type_by_groups[group] ?? this.tag_type_by_groups.tag_default
      )
    },
    afterClosePopover() {
      this.resetPopover()
    },
    resetNewTagForm() {
      this.search_input = ""
      this.new_tag_data = {
        keyword: "",
        color_hex_code: TAG_CHAT_DEFAULT_COLOR,
        group: "",
        type: "",
      }
    },
    resetEditTagForm() {
      this.is_editing_tag = false
      this.edit_input = ""
      this.current_editing_tag = {
        _id: "",
        keyword: "",
        color_hex_code: "",
        group: "",
        type: "",
      }
    },
    resetPopover() {
      this.loading = false
      this.search_input = ""
      this.edit_input = ""
      this.resetNewTagForm()
      this.resetEditTagForm()
      this.closeRemoveDialog()
    },
    resetSkip() {
      this.tag_data.skip = 0
    },
    generateNewTagAPIBody(data) {
      const group = this.tag_group_by_types[this.type]
      switch (this.type) {
        case this.tag_types.tag_contact:
          return {
            keyword: data.keyword,
            group: group,
          }
        case this.tag_types.tag_chat:
          return {
            keyword: data.keyword,
            color_hex_code: data.color_hex_code,
            group: group,
          }
        case this.tag_types.tag_case:
          return {
            keyword: data.keyword,
            group: group,
          }
        case this.tag_types.tag_broadcast:
          return {
            keyword: data.keyword,
            group: group,
          }
        default:
          return {}
      }
    },
    generateEditTagAPIBody(data) {
      const group = this.tag_group_by_types[this.type]
      switch (this.type) {
        case this.tag_types.tag_contact:
          return {
            _id: data._id,
            keyword: data.keyword,
            group: group,
          }
        case this.tag_types.tag_chat:
          return {
            _id: data._id,
            keyword: data.keyword,
            color_hex_code: data.color_hex_code,
            group: group,
          }
        case this.tag_types.tag_case:
          return {
            _id: data._id,
            keyword: data.keyword,
            group: group,
          }
        case this.tag_types.tag_broadcast:
          return {
            _id: data._id,
            keyword: data.keyword,
            group: group,
          }
        default:
          return {}
      }
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
    },
  },
}
