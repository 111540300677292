// The number of channels used to set the threshold.
const CHANNEL_THRESHOLD = 100

// helper function
// chuck string
const chuck = (str, size) => {
  const chucked = []
  for (let i = 0; i < str.length; i += size) {
    chucked.push(str.slice(i, i + size))
  }

  return chucked
}

// find max length of that set
const getMaxBitLength = (bit, size) => {
  return Math.ceil(bit.length / size) * size
}

const transformChannelsOrderIndexToHexCode = (indexes = []) => {
  const bit_size = 8
  const hex_pair_size = 2
  let bin = ""

  for (const index of indexes) {
    if (!bin[index]) {
      bin = bin.padStart(index, "0")
    }

    bin = `1${bin}`
  }

  bin = bin.padStart(getMaxBitLength(bin, bit_size), "0")

  const hex = chuck(bin, bit_size)
    .map((s) => parseInt(s, 2).toString(16).padStart(hex_pair_size, "0"))
    .join("")

  return hex.padStart(getMaxBitLength(hex, bit_size), "0")
}

export default ({ store }, inject) => {
  const apiServiceHelper = {
    generateChannelIdQueryParam(checked_channels = []) {
      const channels_data = store.state.channels || []
      let query_param = {}

      // If a business has more than channel threshold, switch to using query parameters in HEX code
      if (checked_channels.length > CHANNEL_THRESHOLD) {
        const checked_channels_data = channels_data.filter((item) =>
          checked_channels.includes(item._id)
        )
        const order_indexs = checked_channels_data.map((item) => item.order)

        query_param = {
          channel_filter: transformChannelsOrderIndexToHexCode(order_indexs),
        }
      } else {
        query_param = {
          channel_id:
            checked_channels.length !== 0 ? checked_channels : undefined,
        }
      }

      return query_param
    },
  }

  inject("apiServiceHelper", apiServiceHelper)
}
