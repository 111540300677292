export const MAXIMUM_EXPORT_CONTACT = 10000

export const CHAT_ACTION_UPDATE_FIELDS = [
  "_id",
  "state",
  "status",
  "chat_status",
  "end_case_at",
  "last_contact_date",
  "line_reply_token",
  "assignee",
  "last_active_at",
  "assign_at",
  "assign_by",
  "assign_to",
  "current_case",
  "all_case",
  "block_status",
  "updated_at",
]

export const ASSIGN_SELF_UPDATE_FIELDS = [
  "_id",
  "status",
  "chat_status",
  "assignee",
  "assign_by",
  "assign_to",
  "block_status",
  "assign_at",
  "last_contact_date",
  "last_active_at",
  "updated_at",
]

export const END_CHAT_UPDATE_FIELDS = [
  "_id",
  "status",
  "chat_status",
  "assignee",
  "assign_by",
  "assign_to",
  "current_case",
  "block_status",
  "assign_at",
  "last_contact_date",
  "last_active_at",
  "updated_at",
]

export const DEFAULT_UPDATE_FIELDS = [
  "_id",
  "tags",
  "state",
  "notes",
  "status",
  "chat_status",
  "end_case_at",
  "last_contact_date",
  "line_reply_token",
  "assignee",
  "last_active_at",
  "assign_at",
  "assign_by",
  "assign_to",
  "labels",
  "current_case",
  "tags_migrate_objectid",
  "all_case",
  "block_status",
  "display_name",
  "first_name",
  "last_name",
  "customer_id",
  "phone",
  "email",
  "country",
  "city",
  "gender",
  "age",
  "birth_date",
  "auto_assign_action_type",
  "is_auto_assigned",
  "updated_at",
  "sale_owner",
  "sale_owner_updated_at",
  "sale_owner_updated_by",
  "referrals",
]
