import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8dc8c1cc = () => interopDefault(import('../pages/add-business/index.vue' /* webpackChunkName: "pages/add-business/index" */))
const _47ec5e54 = () => interopDefault(import('../pages/create-business.vue' /* webpackChunkName: "pages/create-business" */))
const _72807874 = () => interopDefault(import('../pages/go-to-desktop.vue' /* webpackChunkName: "pages/go-to-desktop" */))
const _15f2a8b7 = () => interopDefault(import('../pages/invite/index.vue' /* webpackChunkName: "pages/invite/index" */))
const _51c5d30e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c82c6430 = () => interopDefault(import('../pages/login-old.vue' /* webpackChunkName: "pages/login-old" */))
const _34059735 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _8b4679e4 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _0d0bfc07 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _8abe8654 = () => interopDefault(import('../pages/select-business.vue' /* webpackChunkName: "pages/select-business" */))
const _2eec2293 = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _5e4cec04 = () => interopDefault(import('../pages/verify-account.vue' /* webpackChunkName: "pages/verify-account" */))
const _532a9190 = () => interopDefault(import('../pages/verify-connect-email.vue' /* webpackChunkName: "pages/verify-connect-email" */))
const _4d48664a = () => interopDefault(import('../pages/invite/expired.vue' /* webpackChunkName: "pages/invite/expired" */))
const _19fbf81c = () => interopDefault(import('../pages/invite/invalid.vue' /* webpackChunkName: "pages/invite/invalid" */))
const _2168fda3 = () => interopDefault(import('../pages/reset-password/accept.vue' /* webpackChunkName: "pages/reset-password/accept" */))
const _1a3c0636 = () => interopDefault(import('../pages/reset-password/resend-email.vue' /* webpackChunkName: "pages/reset-password/resend-email" */))
const _c575c110 = () => interopDefault(import('../pages/reset-password/success.vue' /* webpackChunkName: "pages/reset-password/success" */))
const _30cfa0d9 = () => interopDefault(import('../pages/sign-up/resend-email.vue' /* webpackChunkName: "pages/sign-up/resend-email" */))
const _709459f8 = () => interopDefault(import('../pages/sign-up/success.vue' /* webpackChunkName: "pages/sign-up/success" */))
const _efc3a012 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _551633f6 = () => interopDefault(import('../pages/business/_biz_id/index.vue' /* webpackChunkName: "pages/business/_biz_id/index" */))
const _261c0a28 = () => interopDefault(import('../pages/business/_biz_id/auto-reply/index.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/index" */))
const _4d085f12 = () => interopDefault(import('../pages/business/_biz_id/broadcast/index.vue' /* webpackChunkName: "pages/business/_biz_id/broadcast/index" */))
const _6176e68a = () => interopDefault(import('../pages/business/_biz_id/dashboard/index.vue' /* webpackChunkName: "pages/business/_biz_id/dashboard/index" */))
const _275df4fb = () => interopDefault(import('../pages/business/_biz_id/onboarding/index.vue' /* webpackChunkName: "pages/business/_biz_id/onboarding/index" */))
const _52ca4a06 = () => interopDefault(import('../pages/business/_biz_id/setting/index.vue' /* webpackChunkName: "pages/business/_biz_id/setting/index" */))
const _55b2a752 = () => interopDefault(import('../pages/business/_biz_id/smartchat/index.vue' /* webpackChunkName: "pages/business/_biz_id/smartchat/index" */))
const _e7398e04 = () => interopDefault(import('../pages/business/_biz_id/start-oho.vue' /* webpackChunkName: "pages/business/_biz_id/start-oho" */))
const _77403844 = () => interopDefault(import('../pages/business/_biz_id/start-oho/_platform/index.vue' /* webpackChunkName: "pages/business/_biz_id/start-oho/_platform/index" */))
const _172351ae = () => interopDefault(import('../pages/business/_biz_id/auto-reply/blueprint-mapping.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/blueprint-mapping" */))
const _b6dbc99e = () => interopDefault(import('../pages/business/_biz_id/auto-reply/facebook-comment/index.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/facebook-comment/index" */))
const _186b58b8 = () => interopDefault(import('../pages/business/_biz_id/auto-reply/fallback.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/fallback" */))
const _33e37dc9 = () => interopDefault(import('../pages/business/_biz_id/auto-reply/greeting-message.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/greeting-message" */))
const _c1084b7e = () => interopDefault(import('../pages/business/_biz_id/auto-reply/inform-admin.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/inform-admin" */))
const _438fa58d = () => interopDefault(import('../pages/business/_biz_id/auto-reply/main-menu.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/main-menu" */))
const _29d473ad = () => interopDefault(import('../pages/business/_biz_id/auto-reply/messenger.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/messenger" */))
const _1ad1cc6c = () => interopDefault(import('../pages/business/_biz_id/auto-reply/rich-menu.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/rich-menu" */))
const _3893774c = () => interopDefault(import('../pages/business/_biz_id/auto-reply/toggle-chatbot.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/toggle-chatbot" */))
const _71427982 = () => interopDefault(import('../pages/business/_biz_id/auto-reply/welcome-back-message.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/welcome-back-message" */))
const _6bb69a4b = () => interopDefault(import('../pages/business/_biz_id/automation/team-setting.vue' /* webpackChunkName: "pages/business/_biz_id/automation/team-setting" */))
const _7db28927 = () => interopDefault(import('../pages/business/_biz_id/broadcast/create.vue' /* webpackChunkName: "pages/business/_biz_id/broadcast/create" */))
const _5108689a = () => interopDefault(import('../pages/business/_biz_id/media-library/saved-reply.vue' /* webpackChunkName: "pages/business/_biz_id/media-library/saved-reply" */))
const _8df58dee = () => interopDefault(import('../pages/business/_biz_id/onboarding/contact.vue' /* webpackChunkName: "pages/business/_biz_id/onboarding/contact" */))
const _6b668e6c = () => interopDefault(import('../pages/business/_biz_id/onboarding/smartchat.vue' /* webpackChunkName: "pages/business/_biz_id/onboarding/smartchat" */))
const _5b752abc = () => interopDefault(import('../pages/business/_biz_id/setting/business-holidays.vue' /* webpackChunkName: "pages/business/_biz_id/setting/business-holidays" */))
const _e0a61784 = () => interopDefault(import('../pages/business/_biz_id/setting/business-hours.vue' /* webpackChunkName: "pages/business/_biz_id/setting/business-hours" */))
const _a9833c4e = () => interopDefault(import('../pages/business/_biz_id/setting/contact-channels.vue' /* webpackChunkName: "pages/business/_biz_id/setting/contact-channels" */))
const _4bfc2108 = () => interopDefault(import('../pages/business/_biz_id/setting/integration.vue' /* webpackChunkName: "pages/business/_biz_id/setting/integration" */))
const _1eabcc4e = () => interopDefault(import('../pages/business/_biz_id/setting/integration/_platform/index.vue' /* webpackChunkName: "pages/business/_biz_id/setting/integration/_platform/index" */))
const _e6b3a7d4 = () => interopDefault(import('../pages/business/_biz_id/setting/member.vue' /* webpackChunkName: "pages/business/_biz_id/setting/member" */))
const _5c70223a = () => interopDefault(import('../pages/business/_biz_id/setting/payment.vue' /* webpackChunkName: "pages/business/_biz_id/setting/payment" */))
const _54fe59c8 = () => interopDefault(import('../pages/business/_biz_id/setting/team/index.vue' /* webpackChunkName: "pages/business/_biz_id/setting/team/index" */))
const _0f0173aa = () => interopDefault(import('../pages/business/_biz_id/setting/webhook.vue' /* webpackChunkName: "pages/business/_biz_id/setting/webhook" */))
const _8b1b19e2 = () => interopDefault(import('../pages/business/_biz_id/auto-reply/facebook-comment/_detail.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/facebook-comment/_detail" */))
const _7b48eda7 = () => interopDefault(import('../pages/business/_biz_id/broadcast/status/_type.vue' /* webpackChunkName: "pages/business/_biz_id/broadcast/status/_type" */))
const _6a92dc3a = () => interopDefault(import('../pages/business/_biz_id/setting/team/_detail.vue' /* webpackChunkName: "pages/business/_biz_id/setting/team/_detail" */))
const _6a59500a = () => interopDefault(import('../pages/business/_biz_id/auto-reply/_detail.vue' /* webpackChunkName: "pages/business/_biz_id/auto-reply/_detail" */))
const _5bc3eed5 = () => interopDefault(import('../pages/business/_biz_id/broadcast/_detail.vue' /* webpackChunkName: "pages/business/_biz_id/broadcast/_detail" */))
const _71846b70 = () => interopDefault(import('../pages/business/_biz_id/case/_slug.vue' /* webpackChunkName: "pages/business/_biz_id/case/_slug" */))
const _149da0fc = () => interopDefault(import('../pages/business/_biz_id/contact/_tab.vue' /* webpackChunkName: "pages/business/_biz_id/contact/_tab" */))
const _c801cee2 = () => interopDefault(import('../pages/business/_biz_id/media-library/_type.vue' /* webpackChunkName: "pages/business/_biz_id/media-library/_type" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-business",
    component: _8dc8c1cc,
    name: "add-business"
  }, {
    path: "/create-business",
    component: _47ec5e54,
    name: "create-business"
  }, {
    path: "/go-to-desktop",
    component: _72807874,
    name: "go-to-desktop"
  }, {
    path: "/invite",
    component: _15f2a8b7,
    name: "invite"
  }, {
    path: "/login",
    component: _51c5d30e,
    name: "login"
  }, {
    path: "/login-old",
    component: _c82c6430,
    name: "login-old"
  }, {
    path: "/logout",
    component: _34059735,
    name: "logout"
  }, {
    path: "/register",
    component: _8b4679e4,
    name: "register"
  }, {
    path: "/reset-password",
    component: _0d0bfc07,
    name: "reset-password"
  }, {
    path: "/select-business",
    component: _8abe8654,
    name: "select-business"
  }, {
    path: "/sign-up",
    component: _2eec2293,
    name: "sign-up"
  }, {
    path: "/verify-account",
    component: _5e4cec04,
    name: "verify-account"
  }, {
    path: "/verify-connect-email",
    component: _532a9190,
    name: "verify-connect-email"
  }, {
    path: "/invite/expired",
    component: _4d48664a,
    name: "invite-expired"
  }, {
    path: "/invite/invalid",
    component: _19fbf81c,
    name: "invite-invalid"
  }, {
    path: "/reset-password/accept",
    component: _2168fda3,
    name: "reset-password-accept"
  }, {
    path: "/reset-password/resend-email",
    component: _1a3c0636,
    name: "reset-password-resend-email"
  }, {
    path: "/reset-password/success",
    component: _c575c110,
    name: "reset-password-success"
  }, {
    path: "/sign-up/resend-email",
    component: _30cfa0d9,
    name: "sign-up-resend-email"
  }, {
    path: "/sign-up/success",
    component: _709459f8,
    name: "sign-up-success"
  }, {
    path: "/",
    component: _efc3a012,
    name: "index"
  }, {
    path: "/business/:biz_id",
    component: _551633f6,
    name: "business-biz_id"
  }, {
    path: "/business/:biz_id?/auto-reply",
    component: _261c0a28,
    name: "business-biz_id-auto-reply"
  }, {
    path: "/business/:biz_id?/broadcast",
    component: _4d085f12,
    name: "business-biz_id-broadcast"
  }, {
    path: "/business/:biz_id?/dashboard",
    component: _6176e68a,
    name: "business-biz_id-dashboard"
  }, {
    path: "/business/:biz_id?/onboarding",
    component: _275df4fb,
    name: "business-biz_id-onboarding"
  }, {
    path: "/business/:biz_id?/setting",
    component: _52ca4a06,
    name: "business-biz_id-setting"
  }, {
    path: "/business/:biz_id?/smartchat",
    component: _55b2a752,
    name: "business-biz_id-smartchat"
  }, {
    path: "/business/:biz_id?/start-oho",
    component: _e7398e04,
    name: "business-biz_id-start-oho",
    children: [{
      path: ":platform",
      component: _77403844,
      name: "business-biz_id-start-oho-platform"
    }]
  }, {
    path: "/business/:biz_id?/auto-reply/blueprint-mapping",
    component: _172351ae,
    name: "business-biz_id-auto-reply-blueprint-mapping"
  }, {
    path: "/business/:biz_id?/auto-reply/facebook-comment",
    component: _b6dbc99e,
    name: "business-biz_id-auto-reply-facebook-comment"
  }, {
    path: "/business/:biz_id?/auto-reply/fallback",
    component: _186b58b8,
    name: "business-biz_id-auto-reply-fallback"
  }, {
    path: "/business/:biz_id?/auto-reply/greeting-message",
    component: _33e37dc9,
    name: "business-biz_id-auto-reply-greeting-message"
  }, {
    path: "/business/:biz_id?/auto-reply/inform-admin",
    component: _c1084b7e,
    name: "business-biz_id-auto-reply-inform-admin"
  }, {
    path: "/business/:biz_id?/auto-reply/main-menu",
    component: _438fa58d,
    name: "business-biz_id-auto-reply-main-menu"
  }, {
    path: "/business/:biz_id?/auto-reply/messenger",
    component: _29d473ad,
    name: "business-biz_id-auto-reply-messenger"
  }, {
    path: "/business/:biz_id?/auto-reply/rich-menu",
    component: _1ad1cc6c,
    name: "business-biz_id-auto-reply-rich-menu"
  }, {
    path: "/business/:biz_id?/auto-reply/toggle-chatbot",
    component: _3893774c,
    name: "business-biz_id-auto-reply-toggle-chatbot"
  }, {
    path: "/business/:biz_id?/auto-reply/welcome-back-message",
    component: _71427982,
    name: "business-biz_id-auto-reply-welcome-back-message"
  }, {
    path: "/business/:biz_id?/automation/team-setting",
    component: _6bb69a4b,
    name: "business-biz_id-automation-team-setting"
  }, {
    path: "/business/:biz_id?/broadcast/create",
    component: _7db28927,
    name: "business-biz_id-broadcast-create"
  }, {
    path: "/business/:biz_id?/media-library/saved-reply",
    component: _5108689a,
    name: "business-biz_id-media-library-saved-reply"
  }, {
    path: "/business/:biz_id?/onboarding/contact",
    component: _8df58dee,
    name: "business-biz_id-onboarding-contact"
  }, {
    path: "/business/:biz_id?/onboarding/smartchat",
    component: _6b668e6c,
    name: "business-biz_id-onboarding-smartchat"
  }, {
    path: "/business/:biz_id?/setting/business-holidays",
    component: _5b752abc,
    name: "business-biz_id-setting-business-holidays"
  }, {
    path: "/business/:biz_id?/setting/business-hours",
    component: _e0a61784,
    name: "business-biz_id-setting-business-hours"
  }, {
    path: "/business/:biz_id?/setting/contact-channels",
    component: _a9833c4e,
    name: "business-biz_id-setting-contact-channels"
  }, {
    path: "/business/:biz_id?/setting/integration",
    component: _4bfc2108,
    name: "business-biz_id-setting-integration",
    children: [{
      path: ":platform",
      component: _1eabcc4e,
      name: "business-biz_id-setting-integration-platform"
    }]
  }, {
    path: "/business/:biz_id?/setting/member",
    component: _e6b3a7d4,
    name: "business-biz_id-setting-member"
  }, {
    path: "/business/:biz_id?/setting/payment",
    component: _5c70223a,
    name: "business-biz_id-setting-payment"
  }, {
    path: "/business/:biz_id?/setting/team",
    component: _54fe59c8,
    name: "business-biz_id-setting-team"
  }, {
    path: "/business/:biz_id?/setting/webhook",
    component: _0f0173aa,
    name: "business-biz_id-setting-webhook"
  }, {
    path: "/business/:biz_id?/auto-reply/facebook-comment/:detail",
    component: _8b1b19e2,
    name: "business-biz_id-auto-reply-facebook-comment-detail"
  }, {
    path: "/business/:biz_id?/broadcast/status/:type?",
    component: _7b48eda7,
    name: "business-biz_id-broadcast-status-type"
  }, {
    path: "/business/:biz_id?/setting/team/:detail",
    component: _6a92dc3a,
    name: "business-biz_id-setting-team-detail"
  }, {
    path: "/business/:biz_id?/auto-reply/:detail?",
    component: _6a59500a,
    name: "business-biz_id-auto-reply-detail"
  }, {
    path: "/business/:biz_id?/broadcast/:detail",
    component: _5bc3eed5,
    name: "business-biz_id-broadcast-detail"
  }, {
    path: "/business/:biz_id?/case/:slug?",
    component: _71846b70,
    name: "business-biz_id-case-slug"
  }, {
    path: "/business/:biz_id?/contact/:tab?",
    component: _149da0fc,
    name: "business-biz_id-contact-tab"
  }, {
    path: "/business/:biz_id?/media-library/:type?",
    component: _c801cee2,
    name: "business-biz_id-media-library-type"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
