
import _ from "lodash"

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    show_untag_button: {
      type: Boolean,
      default: true,
    },
    is_highlight: {
      type: Boolean,
      default: false,
    },
    data_id: {
      type: String,
      default: "",
    },
  },
  computed: {
    keyword() {
      return this.$textHelper.convertHtmlToString(
        _.get(this.data, "keyword", "")
      )
    },
    highlight_word() {
      return _.get(this.data, "highlight_word", "")
    },
  },
  methods: {
    onClickUntag() {
      this.$emit("onClickUntag", this.data)
    },
  },
}
