import _ from "lodash"

const default_feature_limitation = {
  smartchat_messaging_window_hours: null,
  // Accept all file type, set smartchat_accept_file_types to ''
  smartchat_accept_file_types: "",
  smartchat_max_attachments_per_reply: 14,

  smartchat_max_attachments_per_upload: 14,
  smartchat_max_attachment_mb_size_per_upload: 10,
  media_library_max_attachment_mb_size_per_upload: 10,

  smartchat_max_message_character: 2000,
  smartchat_send_message_max_image_mb_size: 10,
  smartchat_send_message_max_video_mb_size: 10,
  smartchat_send_message_max_file_mb_size: 10,
}

const state = () => ({
  feature_flag: {
    auto_reply: ["facebook", "line"],
    broadcast: ["line"],
    smartchat_message_read: ["facebook", "tiktok"],
    smartchat_media_library: ["facebook", "line", "instagram"],
    smartchat_saved_replies: ["facebook", "line", "instagram"],
    smartchat_messaging_window: ["facebook", "instagram", "tiktok"],
  },
  feature_limitation: {
    facebook: {
      ...default_feature_limitation,
      smartchat_messaging_window_hours: 168,
      smartchat_max_attachment_mb_size_per_upload: 8,
    },
    line: {
      ...default_feature_limitation,
      smartchat_max_message_character: 5000,
      smartchat_send_message_max_video_mb_size: 200,
      smartchat_send_message_max_file_mb_size: "not-support",
    },
    instagram: {
      ...default_feature_limitation,
      smartchat_messaging_window_hours: 168,

      smartchat_max_attachment_mb_size_per_upload: 8,

      smartchat_max_message_character: 1000,
      smartchat_send_message_max_image_mb_size: 8,
      smartchat_send_message_max_video_mb_size: "not-support",
      smartchat_send_message_max_file_mb_size: "not-support",
    },
    tiktok: {
      ...default_feature_limitation,
      smartchat_messaging_window_hours: 48,

      smartchat_accept_file_types: ".jpg, .png, .jpeg",
      smartchat_max_attachments_per_reply: 9,
      smartchat_max_attachments_per_upload: 9,
      smartchat_max_attachment_mb_size_per_upload: 3,

      smartchat_max_message_character: 6000,
      smartchat_send_message_max_image_mb_size: 3,
      smartchat_send_message_max_video_mb_size: "not-support",
      smartchat_send_message_max_file_mb_size: "not-support",
    },
  },
})

const getters = {
  app_platforms: (state, getters, rootState) => {
    return [
      {
        display_name: "Facebook Page",
        name: "facebook",
        icon: rootState.icon.facebook,
        limitation: {
          character: 2000,
          attachment_size: {
            image: 10 * Math.pow(10, 6),
            video: 10 * Math.pow(10, 6),
            file: 10 * Math.pow(10, 6),
          },
        },
      },
      {
        display_name: "LINE Official Account",
        name: "line",
        icon: rootState.icon.line,
      },
      {
        display_name: "Instagram",
        name: "instagram",
        icon: rootState.icon.instagram,
      },
      {
        display_name: "TikTok Business Account",
        name: "tiktok",
        icon: rootState.icon.tiktok,
      },
    ]
  },
  contact_platforms: (state, getters, rootState) => {
    return [
      {
        display_name: "Messenger",
        name: "facebook",
        icon: rootState.icon.messenger,
      },
      {
        display_name: "Facebook Page",
        name: "facebook-page",
        icon: rootState.icon.facebook,
      },
      {
        display_name: "LINE OA",
        name: "line",
        icon: rootState.icon.line_app,
      },
      {
        display_name: "Instagram",
        name: "instagram",
        icon: rootState.icon.instagram,
      },
      {
        display_name: "TikTok",
        name: "tiktok",
        icon: rootState.icon.tiktok,
      },
    ]
  },
  getAppPlatform: (state, getters) => (platform) => {
    return _.find(getters.app_platforms, { name: platform })
  },
  getContactPlatform: (state, getters) => (platform) => {
    const res = _.find(getters.contact_platforms, { name: platform })
    return _.get(res, "display_name")
  },
  getAppPlatformIcon: (state, getters) => (platform) => {
    return _.chain(getters.app_platforms)
      .find({ name: platform })
      .get("icon")
      .value()
  },
  getContactPlatformIcon: (state, getters) => (platform) => {
    return _.chain(getters.contact_platforms)
      .find({ name: platform })
      .get("icon")
      .value()
  },
  getAppPlatformDisplayName: (state, getters) => (platform) => {
    return _.chain(getters.app_platforms)
      .find({ name: platform })
      .get("display_name")
      .value()
  },
  getContactPlatformDisplayName: (state, getters) => (platform) => {
    return _.chain(getters.contact_platforms)
      .find({ name: platform })
      .get("display_name")
      .value()
  },
  getFeatureFlagByPlatform: (state) => (platform) => {
    const feature_flag = state.feature_flag
    let result = {}

    for (const feature_name in feature_flag) {
      const support_platforms = feature_flag[feature_name]
      result[feature_name] = support_platforms.includes(platform)
    }

    return result
  },
  getFeatureLimitationByPlatform: (state) => (platform) => {
    return state.feature_limitation[`${platform}`]
  },
}

export default { state, getters }
